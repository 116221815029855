// extracted by mini-css-extract-plugin
export var container = "Homepage-module--container--c3337";
export var content = "Homepage-module--content--64230";
export var contentOverFooter = "Homepage-module--contentOverFooter--92348";
export var fixedParallaxe = "Homepage-module--fixedParallaxe--e6b72";
export var item = "Homepage-module--item--e30fa";
export var overlay = "Homepage-module--overlay--fb120";
export var sticky = "Homepage-module--sticky--85d40";
export var title = "Homepage-module--title--b2e71";
export var tricksPaddingButton = "Homepage-module--tricksPaddingButton--1573d";
export var video = "Homepage-module--video--36927";
export var wrap = "Homepage-module--wrap--e3ee1";
export var wrapper = "Homepage-module--wrapper--349f2";