import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";

import { FlatButton } from "../../../../components/Button/Button";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { IndexPageContext } from "../../../../helpers/pagesContext";
import { IDENTITY } from "../../../../helpers/route";
import * as styles from "./Discover.module.css";

export const Discover = () => {
  const {
    discover,
    culture,
    discoverAction,
    discoverAction2,
    presentationText,
  } = useContext(IndexPageContext);

  const mainImage = discover.find(
    (item) => item.title === "home-discover-main"
  );
  const seaImage = discover.find((item) => item.title === "home-discover-sea");
  const backgroundImage = discover.find(
    (item) => item.title === "home-discover-background"
  );

  return (
    <section
      style={{
        backgroundImage: `url(${backgroundImage.file.url})`,
      }}
      className={styles.root}
    >
      <div
        style={{
          backgroundImage: `url(${seaImage.file.url})`,
        }}
        className={styles.wrap}
      >
        <div className={styles.container}>
          <div className={styles.title}>
            <SlipTransition lines={[discoverAction2, culture]} />
          </div>
          <div className={styles.content}>
            <SlideTransition delay={0.2}>
              <div className={styles.image}>
                <GatsbyImage
                  image={mainImage?.gatsbyImageData}
                  alt={mainImage?.file.fileName}
                  width={600}
                />
                <div className={styles.overlay} />
              </div>
            </SlideTransition>
            <div className={styles.presentation}>
              <SlideTransition delay={0.5}>
                <div className={styles.text}>
                  {presentationText.presentationText}
                </div>
              </SlideTransition>
              <SlideTransition delay={0.8}>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    typeof window !== "undefined" &&
                      window.gtag("event", "click", {
                        category: "index_discover_click",
                        action: "Click",
                        label: discoverAction,
                      });
                  }}
                  className={styles.button}
                >
                  <Link to={localeLink(IDENTITY)}>
                    <FlatButton label={discoverAction} size="large" />
                  </Link>
                </div>
              </SlideTransition>
            </div>
          </div>
        </div>
        {/* <div className={styles.hidden} /> */}
      </div>
    </section>
  );
};
