import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/seo";
import { IndexPageContext } from "../helpers/pagesContext";
import Homepage from "../scenes/Homepage/Homepage";

export default function Home(props) {
  return (
    <Layout data={props.data.contentfulMenuLabel}>
      <Seo
        title="Pure Player du Test Logiciel"
        description="Zenity, Pure Player du Digital testing en France, depuis 2010 nos experts du test assurent la qualité de vos applications. Notre objectif est la réussite et la satisfaction de nos clients."
      />
      <IndexPageContext.Provider value={props.data.contentfulIndex}>
        <Homepage />
      </IndexPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulIndex(node_locale: { eq: $locale }) {
      node_locale
      trustInQuality
      legal
      explore
      voyage
      voyageAction
      deployAction
      rollOut
      identity
      discovering
      culture
      discoverAction
      discoverAction2
      exploreAction
      ourUnivers
      heartOf
      humanAdventure
      travelText
      meaning
      innovationPart1
      innovationPart2
      likeToJoin
      exploreText {
        exploreText
      }
      innovationText {
        innovationText
      }
      presentationText {
        presentationText
      }
      heroVimeoUrl
      herosVimeoUrl
      discover {
        title
        file {
          url
          fileName
        }
        gatsbyImageData(placeholder: BLURRED)
      }
      carousel {
        id
        description
        title
        gatsbyImageData(placeholder: BLURRED)
      }
      travel {
        file {
          url
          fileName
          contentType
        }
      }
      deploy {
        gatsbyImageData(width: 780, height: 400, placeholder: BLURRED)
      }
      footer {
        file {
          url
          fileName
          contentType
        }
      }
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
