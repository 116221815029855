// extracted by mini-css-extract-plugin
export var button = "Discover-module--button--3cdb7";
export var container = "Discover-module--container--c99bb";
export var content = "Discover-module--content--d0bd2";
export var hidden = "Discover-module--hidden--98026";
export var image = "Discover-module--image--fa892";
export var overlay = "Discover-module--overlay--886be";
export var presentation = "Discover-module--presentation--5a6a1";
export var root = "Discover-module--root--51270";
export var text = "Discover-module--text--26973";
export var title = "Discover-module--title--da8fc";
export var wrap = "Discover-module--wrap--1a551";