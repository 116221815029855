import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { cloneElement } from "react";

import { localeLink } from "../../../../../helpers/localeLink";
import { UNIVERS } from "../../../../../helpers/route";
import * as styles from "./CarouselItem.module.css";

export const CarouselItem = ({ children, title }) => {
  return (
    <Link to={localeLink(UNIVERS)}>
      <div className={styles.item}>
        {cloneElement(children, { className: styles.image })}
        <div className={styles.background} />
        <div className={styles.title}>{title}</div>
      </div>
    </Link>
  );
};

CarouselItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
