import { Link } from "gatsby";
import React, { useContext } from "react";

import { FlatButton } from "../../../../components/Button/Button";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { IndexPageContext } from "../../../../helpers/pagesContext";
import { INSIDE_ZENITY } from "../../../../helpers/route";
import * as styles from "./Travel.module.css";

export const Travel = () => {
  const { travel, voyageAction, heartOf, humanAdventure, travelText, voyage } =
    useContext(IndexPageContext);

  return (
    <section
      style={{ backgroundImage: `url(${travel.file.url})` }}
      className={styles.root}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <SlipTransition
              lines={[voyageAction, heartOf, humanAdventure]}
              color="#fff"
            />
          </div>
          <div className={styles.presentation}>
            <SlideTransition delay={0.2}>
              <div className={styles.text}>{travelText}</div>
            </SlideTransition>
            <SlideTransition delay={0.5}>
              <div className={styles.button}>
                <Link to={localeLink(INSIDE_ZENITY)}>
                  <FlatButton label={voyage} color="white" />
                </Link>
              </div>
            </SlideTransition>
          </div>
        </div>
      </div>
    </section>
  );
};
