import { Link } from "gatsby";
import React, { useContext } from "react";

import { FlatButton } from "../../../../components/Button/Button";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { IndexPageContext } from "../../../../helpers/pagesContext";
import { UNIVERS } from "../../../../helpers/route";
import * as styles from "./Explore.module.css";

export const Explore = () => {
  const { exploreAction, ourUnivers, exploreText, discovering } =
    useContext(IndexPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <SlipTransition lines={[exploreAction, ourUnivers]} />
          </div>
          <div className={styles.presentation}>
            <SlideTransition delay={0.2}>
              <div className={styles.text}>{exploreText.exploreText}</div>
            </SlideTransition>
            <SlideTransition delay={0.5}>
              <div className={styles.button}>
                <Link to={localeLink(UNIVERS)}>
                  <FlatButton label={discovering} size="large" />
                </Link>
              </div>
            </SlideTransition>
          </div>
        </div>
      </div>
    </section>
  );
};
