import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useEffect, useRef, useState } from "react";

import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { IndexPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Hero.module.css";

export const Hero = () => {
  const ref = useRef();
  const { herosVimeoUrl, discovering, trustInQuality } =
    useContext(IndexPageContext);
  const [heroVideo, setHeroVideo] = useState(
    "https://player.vimeo.com/video/653690111?h=436ab9844c"
  );

  const handleClick = () => {
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setHeroVideo(herosVimeoUrl[(Math.random() * herosVimeoUrl.length) | 0]);
    return () => {};
  }, []);

  return (
    <>
      <section className={styles.root}>
        <div className={styles.iframeWrapper}>
          <iframe
            src={`${heroVideo}&background=1`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <div className={styles.overlay} />
        <div className={styles.container}>
          <SlipTransition
            lines={[trustInQuality]}
            color="#fff"
            important={true}
            options={{ delay: 0.4, animateY: -150 }}
          />
          <div className={styles.scrollIndicator}>
            <div className={styles.scrollTitle}>{discovering}</div>
            <div className={styles.scrollIcon} onClick={handleClick}>
              <StaticImage
                src="../../../../resources/images/landing-hero/scroll-curseur.png"
                alt="scroller"
                width={34}
              />
            </div>
          </div>
        </div>
      </section>
      <div ref={ref} />
    </>
  );
};
