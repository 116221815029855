/* eslint-disable import/no-unresolved */
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { IndexPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Carousel.module.css";
import { CarouselItem } from "./components/CarouselItem";

export const Carousel = () => {
  const context = useContext(IndexPageContext);
  const swiperSettings = {
    modules: [Pagination],
    grabCursor: true,
    spaceBetween: 15,
    slidesPerView: 1,
    slidesPerGroup: 1,
    breakpoints: {
      600: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      1340: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1600: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
    },
    pagination: {
      clickable: true,
      renderBullet: (_, className) => `<div class="${className}"></div>`,
    },
  };

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <Swiper {...swiperSettings}>
          {context.carousel.map((item) => (
            <SwiperSlide key={item.id}>
              <SlideTransition>
                <CarouselItem title={item.description}>
                  <GatsbyImage image={item.gatsbyImageData} alt={item.title} />
                </CarouselItem>
              </SlideTransition>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};
