import clsx from "clsx";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";

import { FlatButton } from "../../../../components/Button/Button";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { IndexPageContext } from "../../../../helpers/pagesContext";
import { FORMATION } from "../../../../helpers/route";
import * as styles from "./Deploy.module.css";

export const Deploy = () => {
  const {
    rollOut,
    deploy,
    deployAction,
    meaning,
    innovationPart1,
    innovationPart2,
    innovationText,
    node_locale,
  } = useContext(IndexPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.titleWrap}>
          <SlipTransition
            lines={[
              deployAction,
              meaning,
              <div
                key={0}
                className={clsx(
                  node_locale === "en-US" && styles.innovationEnglish
                )}
              >
                {innovationPart1}
                <span className={styles.color}>{innovationPart2}</span>
              </div>,
            ]}
          />
        </div>
        <div className={styles.content}>
          <SlideTransition delay={0.2}>
            {/* <ModalViewer video={modalVideo} /> */}
            <div
              style={{
                maxWidth: 780,
                width: "100%",
                margin: "auto",
              }}
            >
              <GatsbyImage image={deploy.gatsbyImageData} alt="bulb" />
            </div>
          </SlideTransition>
          <div className={styles.presentation}>
            <SlideTransition delay={0.5}>
              <div className={styles.text}>{innovationText.innovationText}</div>
            </SlideTransition>
            <SlideTransition delay={0.8}>
              <div className={styles.button}>
                <Link to={localeLink(FORMATION)}>
                  <FlatButton label={rollOut} size="large" />
                </Link>
              </div>
            </SlideTransition>
          </div>
        </div>
      </div>
    </section>
  );
};
