import { Link } from "gatsby";
import React, { useContext } from "react";

import { BorderButton } from "../../components/Button/Button";
import { Footer } from "../../components/Footer/Footer";
import { SlipTransition } from "../../components/SlipTransition/SlipTransition";
import { localeLink } from "../../helpers/localeLink";
import { IndexPageContext } from "../../helpers/pagesContext";
import { IDENTITY } from "../../helpers/route";
import { Carousel } from "./components/Carousel/Carousel";
import { Deploy } from "./components/Deploy/Deploy";
import { Discover } from "./components/Discover/Discover";
import { Explore } from "./components/Explore/Explore";
// import { Flaps } from "./components/Flaps/Flaps";
import { Hero } from "./components/Hero/Hero";
import { Travel } from "./components/Travel/Travel";
import * as styles from "./Homepage.module.css";

export default function Homepage() {
  const { footer, explore, voyage, rollOut, identity, node_locale } =
    useContext(IndexPageContext);

  return (
    <div>
      <Hero />
      <div className={styles.wrap} style={{ zIndex: 13 }}>
        <Discover />
      </div>
      <div className={styles.wrap} style={{ zIndex: 12 }}>
        <div className={styles.fixedParallaxe}>{explore}</div>
        <Explore />
        <Carousel />
      </div>
      <div className={styles.wrap} style={{ zIndex: 11 }}>
        <div className={styles.content}>
          <div className={styles.item}>
            <div className={styles.sticky} style={{ color: "#7f5616d1" }}>
              {voyage}
            </div>
          </div>
        </div>
        <Travel />
        {/* <Flaps /> */}
      </div>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.item}>
            <div className={styles.sticky}>{rollOut}</div>
          </div>
        </div>
        <Deploy />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.overlay}></div>
        <video playsInline autoPlay loop muted className={styles.video}>
          <source type={footer.file.contentType} src={footer.file.url} />
        </video>
        <div className={styles.container}>
          <div className={styles.contentOverFooter}>
            <div className={styles.title}>
              <SlipTransition lines={[identity]} color="#fff" />
            </div>
            <div className={styles.tricksPaddingButton}>
              <Link to={localeLink(IDENTITY)}>
                <BorderButton label={explore} color="#fff" />
              </Link>
            </div>
          </div>
        </div>
        <Footer lang={node_locale} />
      </div>
    </div>
  );
}
